<template>
  <div class="">
    <div class="linechart" ref="chartref" style="position: relative">
      <line-chart :chart-data="chartData" :options="chartOptions"></line-chart>
    </div>
  </div>
</template>

<script>
import LineChart from "./LineChart.js";
import { convertRGBA } from "@/mixins/ConvertRGBA";

export default {
  components: {
    LineChart,
  },
  props: [
    "playerone",
    "playertwo",
    "playeronedivision",
    "playertwodivision",
    "playeroneresults",
    "playertworesults",
    "layout",
  ],
  mixins: [convertRGBA],
  computed: {
    maxTick() {
      let max = 0;
      for (let i = 0; i < this.playeroneresults.length; i++) {
        if (this.playeroneresults[i] > max) {
          max = this.playeroneresults[i];
        }
      }
      for (let i = 0; i < this.playertworesults.length; i++) {
        if (this.playertworesults[i] > max) {
          max = this.playertworesults[i];
        }
      }

      return max;
    },
    chartData() {
      return {
        centerText: "",
        showValue: false,
        labels: this.layout,
        datasets: [
          {
            label: this.playerone,
            data: this.playeroneresults,
            fill: false,
            borderWidth: 4,
            pointRadius: 9,
            pointHoverRadius: 8,
            pointBorderWidth: 0,
            pointHoverBackgroundColor: "#05B5BC",
            pointBackgroundColor: "#05B5BC",
            borderColor: "#E5F8F8",
            borderJoinStyle: "round",
          },
          {
            label: this.playertwo,
            data: this.playertworesults,
            fill: false,
            borderWidth: 4,
            pointRadius: 9,
            pointHoverRadius: 8,
            pointBorderWidth: 0,
            borderColor: "#F7E5EF",
            pointHoverBackgroundColor: "#B40068",
            pointBackgroundColor: "#B40068",
          },
        ],
      };
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        layout: {
          padding: {
            top: 12,
            right: 0,
            left: 0,
          },
        },
        legend: {
          display: false,
        },
        elements: {
          point: {
            borderWidth: 2,
          },
          line: {
            tension: 0,
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: "#e9ebee",
                drawBorder: false,
              },
              ticks: {
                stepSize: 2,
                fontSize: 16,
                fontFamily: "Gilroy-Bold",
                padding: 15,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: "#e9ebee",
                drawBorder: false,
              },
              ticks: {
                fontSize: 16,
                fontFamily: "Gilroy-Bold",
                min: 1,
                max: this.maxTick,
                stepSize: 1,
                padding: 15,
              },
            },
          ],
        },
      };
    },
  },
  data() {
    return {};
  },
  beforeMount() {},
  mounted() {},
};
</script>

<style lang="scss">
.names {
  p {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 16px;
    @include Gilroy-Bold;
    &.first {
      color: $avocado;
    }
    &.second {
      color: $peach;
    }
  }
}

.linechart {
  max-width: 100%;
  margin: 0;
  div {
    position: relative;
    max-height: 200px;
  }
}
@media (min-width: 1200px) {
  .linechart {
    div {
      position: relative;
      max-height: 350px;
    }
  }
}
</style>
