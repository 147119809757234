<template>
  <div class="row" v-if="!loading">
    <div class="col-12" v-if="poolData.status != 'COMPLETED'">
      <div class="placeholder-message">
        <h4>
          {{ poolData.round.pools.length > 1 ? "Pool" : "Round" }} not finished
          yet
        </h4>
        <p>
          Once this
          {{ poolData.round.pools.length > 1 ? "pool" : "round" }} is finished
          you’ll be able to compare players.
        </p>
      </div>
    </div>
    <div class="col-12 col-xl-8" v-else>
      <span>
        <div class="selection-container">
          <div class="player-select">
            <select name="playerone" class="left playerone" v-model="playerOne">
              <option :value="{}" selected>Select player</option>
              <option
                v-for="player in selectablePlayerOnes"
                :key="player.id"
                :value="player"
              >
                {{ player.firstName }} {{ player.lastName }}
              </option>
            </select>
          </div>
          <div class="versus">VS</div>
          <div class="player-select">
            <select
              name="playertwo"
              class="right playertwo"
              v-model="playerTwo"
            >
              <option :value="{}" selected>Select player</option>
              <option
                v-for="player in selectablePlayerTwos"
                :key="player.id"
                :value="player"
              >
                {{ player.firstName }} {{ player.lastName }}
              </option>
            </select>
          </div>
        </div>
        <table>
          <tbody>
            <tr>
              <td
                class="player first rounded"
                :class="{
                  fat: winnerCalc(
                    playerOneStats.result,
                    playerTwoStats.result,
                    false
                  ),
                }"
              >
                {{ showIfNotZero(playerOneStats.result, false) }}
              </td>
              <td class="stat-name">Result</td>
              <td
                class="player second rounded"
                :class="{
                  fat: winnerCalc(
                    playerTwoStats.result,
                    playerOneStats.result,
                    false
                  ),
                }"
              >
                {{ showIfNotZero(playerTwoStats.result, false) }}
              </td>
            </tr>
            <tr>
              <td
                class="player first"
                :class="{
                  fat: winnerCalc(
                    playerOneStats.aces,
                    playerTwoStats.aces,
                    true
                  ),
                }"
              >
                {{ showIfNotZero(playerOneStats.aces, false) }}
              </td>
              <td class="stat-name">Aces</td>
              <td
                class="player second"
                :class="{
                  fat: winnerCalc(
                    playerTwoStats.aces,
                    playerOneStats.aces,
                    true
                  ),
                }"
              >
                {{ showIfNotZero(playerTwoStats.aces, false) }}
              </td>
            </tr>
            <tr>
              <td
                class="player first"
                :class="{
                  fat: winnerCalc(
                    playerOneStats.eagles,
                    playerTwoStats.eagles,
                    true
                  ),
                }"
              >
                {{ showIfNotZero(playerOneStats.eagles, true) }}
              </td>
              <td class="stat-name">Eagles</td>
              <td
                class="player second"
                :class="{
                  fat: winnerCalc(
                    playerTwoStats.eagles,
                    playerTwoStats.eagles,
                    true
                  ),
                }"
              >
                {{ showIfNotZero(playerTwoStats.eagles, true) }}
              </td>
            </tr>
            <tr>
              <td
                class="player first"
                :class="{
                  fat: winnerCalc(
                    playerOneStats.birdies,
                    playerTwoStats.birdies,
                    true
                  ),
                }"
              >
                {{ showIfNotZero(playerOneStats.birdies, true) }}
              </td>
              <td class="stat-name">Birdies</td>
              <td
                class="player second"
                :class="{
                  fat: winnerCalc(
                    playerTwoStats.birdies,
                    playerOneStats.birdies,
                    true
                  ),
                }"
              >
                {{ showIfNotZero(playerTwoStats.birdies, true) }}
              </td>
            </tr>
            <!-- <tr>
            <td class="player" :class="{fat: winnerCalc(playerOneStats.pars, playerTwoStats.pars ,true)}">
              {{ showIfNotZero(playerOneStats.pars, true) }}
            </td>
            <td class="stat-name">Par</td>
            <td class="player" :class="{fat: winnerCalc(playerTwoStats.pars, playerOneStats.pars ,true)}">
              {{ showIfNotZero(playerTwoStats.pars, true) }}
            </td>
          </tr>-->
            <tr>
              <td
                class="player first"
                :class="{
                  fat: winnerCalc(
                    playerOneStats.bogeys,
                    playerTwoStats.bogeys,
                    false
                  ),
                }"
              >
                {{ showIfNotZero(playerOneStats.bogeys, true) }}
              </td>
              <td class="stat-name">Bogeys</td>
              <td
                class="player second"
                :class="{
                  fat: winnerCalc(
                    playerTwoStats.bogeys,
                    playerOneStats.bogeys,
                    false
                  ),
                }"
              >
                {{ showIfNotZero(playerTwoStats.bogeys, true) }}
              </td>
            </tr>
            <tr>
              <td
                class="player first"
                :class="{
                  fat: winnerCalc(
                    playerOneStats.doubleBogeys,
                    playerTwoStats.doubleBogeys,
                    false
                  ),
                }"
              >
                {{ showIfNotZero(playerOneStats.doubleBogeys, true) }}
              </td>
              <td class="stat-name">Double B</td>
              <td
                class="player second"
                :class="{
                  fat: winnerCalc(
                    playerTwoStats.doubleBogeys,
                    playerOneStats.doubleBogeys,
                    false
                  ),
                }"
              >
                {{ showIfNotZero(playerTwoStats.doubleBogeys, true) }}
              </td>
            </tr>
            <tr>
              <td
                class="player first"
                :class="{
                  fat: winnerCalc(
                    playerOneStats.trippleBogeysAbove,
                    playerTwoStats.trippleBogeysAbove,
                    false
                  ),
                }"
              >
                {{ showIfNotZero(playerOneStats.trippleBogeysAbove, true) }}
              </td>
              <td class="stat-name">Triple B (≥)</td>
              <td
                class="player second"
                :class="{
                  fat: winnerCalc(
                    playerTwoStats.trippleBogeysAbove,
                    playerOneStats.trippleBogeysAbove,
                    false
                  ),
                }"
              >
                {{ showIfNotZero(playerTwoStats.trippleBogeysAbove, true) }}
              </td>
            </tr>
            <tr>
              <td
                class="player first"
                :class="{
                  fat: winnerCalc(
                    playerOneStats.circleHits,
                    playerTwoStats.circleHits,
                    true
                  ),
                }"
              >
                {{ showIfNotZero(playerOneStats.circleHits, true) }}
              </td>
              <td class="stat-name">Circle hits</td>
              <td
                class="player second"
                :class="{
                  fat: winnerCalc(
                    playerTwoStats.circleHits,
                    playerOneStats.circleHits,
                    true
                  ),
                }"
              >
                {{ showIfNotZero(playerTwoStats.circleHits, true) }}
              </td>
            </tr>
            <tr>
              <td
                class="player first"
                :class="{
                  fat: winnerCalc(
                    playerOneStats.conversion,
                    playerTwoStats.conversion,
                    true
                  ),
                }"
              >
                {{ showIfNotZero(playerOneStats.conversion, true) }}
              </td>
              <td class="stat-name">Conversions</td>
              <td
                class="player second"
                :class="{
                  fat: winnerCalc(
                    playerTwoStats.conversion,
                    playerOneStats.conversion,
                    true
                  ),
                }"
              >
                {{ showIfNotZero(playerTwoStats.conversion, true) }}
              </td>
            </tr>
            <tr>
              <td
                class="player first"
                :class="{
                  fat: winnerCalc(
                    playerOneStats.insidePutts,
                    playerTwoStats.insidePutts,
                    true
                  ),
                }"
              >
                {{ showIfNotZero(playerOneStats.insidePutts, true) }}
              </td>
              <td class="stat-name">Inside putts</td>
              <td
                class="player second"
                :class="{
                  fat: winnerCalc(
                    playerTwoStats.insidePutts,
                    playerOneStats.insidePutts,
                    true
                  ),
                }"
              >
                {{ showIfNotZero(playerTwoStats.insidePutts, true) }}
              </td>
            </tr>
            <tr>
              <td
                class="player first"
                :class="{
                  fat: winnerCalc(
                    playerOneStats.outsidePutts,
                    playerTwoStats.outsidePutts,
                    true
                  ),
                }"
              >
                {{ showIfNotZero(playerOneStats.outsidePutts, false) }}
              </td>
              <td class="stat-name">Outside putts</td>
              <td
                class="player second"
                :class="{
                  fat: winnerCalc(
                    playerTwoStats.outsidePutts,
                    playerOneStats.outsidePutts,
                    true
                  ),
                }"
              >
                {{ showIfNotZero(playerTwoStats.outsidePutts, false) }}
              </td>
            </tr>
            <tr>
              <td
                class="player first"
                :class="{
                  fat: winnerCalc(
                    playerOneStats.obs,
                    playerTwoStats.obs,
                    false
                  ),
                }"
              >
                {{ showIfNotZero(playerOneStats.obs, false) }}
              </td>
              <td class="stat-name">OBs</td>
              <td
                class="player second"
                :class="{
                  fat: winnerCalc(
                    playerTwoStats.obs,
                    playerOneStats.obs,
                    false
                  ),
                }"
              >
                {{ showIfNotZero(playerTwoStats.obs, false) }}
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="showLineGraph">
          <div
            v-if="$store.state.utility.deviceWidth > 400"
            class="chart-container"
          >
            <VueLineChart
              :playerone="playerOneProps.fullName"
              :playertwo="playerTwoProps.fullName"
              :playeronedivision="playerOneProps.division"
              :playertwodivision="playerTwoProps.division"
              :playeroneresults="playerOneProps.results"
              :playertworesults="playerTwoProps.results"
              :layout="chartLayout()"
            />
          </div>
          <div v-if="$store.state.utility.deviceWidth <= 400">
            <p class="text-center rotate-info">
              Rotate device for hole-by-hole details
              <br />
              <img class="mt-2" src="@/assets/img/graph-dark.svg" />
            </p>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import VueLineChart from "@/components/VueLineChart";
import { convertRGBA } from "@/mixins/ConvertRGBA";
import moment from "moment";
export default {
  name: "PublicEventPoolCompare",
  mixins: [convertRGBA],
  components: { VueLineChart },
  data() {
    return {
      poolData: {},
      loading: true,
      playerOne: {},
      playerTwo: {},
    };
  },
  computed: {
    showLineGraph() {
      if (
        Object.keys(this.playerOne).length !== 0 ||
        Object.keys(this.playerTwo).length !== 0
      ) {
        return true;
      }
      return false;
    },
    playerTwoStats() {
      let stats = {
        result: 0,
        aces: 0,
        eagles: 0,
        birdies: 0,
        pars: 0,
        bogeys: 0,
        doubleBogeys: 0,
        trippleBogeysAbove: 0,
        circleHits: 0,
        conversion: 0,
        insidePutts: 0,
        outsidePutts: 0,
        obs: 0,
      };
      if (Object.keys(this.playerTwo).length > 0) {
        for (var i = 0; i < this.playerTwo.results.length; i++) {
          stats.result += this.playerTwo.results[i].score;
          if (this.playerTwo.results[i].score == 1) stats.aces++;
          else if (
            this.playerTwo.results[i].score -
              this.playerTwo.results[i].hole.par <=
            -2
          )
            stats.eagles++;
          else if (
            this.playerTwo.results[i].score -
              this.playerTwo.results[i].hole.par ==
            -1
          )
            stats.birdies++;
          else if (
            this.playerTwo.results[i].score -
              this.playerTwo.results[i].hole.par ==
            0
          )
            stats.pars++;
          else if (
            this.playerTwo.results[i].score -
              this.playerTwo.results[i].hole.par ==
            1
          )
            stats.bogeys++;
          else if (
            this.playerTwo.results[i].score -
              this.playerTwo.results[i].hole.par ==
            2
          )
            stats.doubleBogeys++;
          else if (
            this.playerTwo.results[i].score -
              this.playerTwo.results[i].hole.par >=
            3
          )
            stats.trippleBogeysAbove++;

          if (this.playerTwo.results[i].isCircleHit) stats.circleHits++;
          if (
            this.playerTwo.results[i].isCircleHit &&
            this.playerTwo.results[i].isInsidePutt
          )
            stats.conversion++;
          if (this.playerTwo.results[i].isInsidePutt) stats.insidePutts++;
          if (this.playerTwo.results[i].isOutsidePutt) stats.outsidePutts++;
          if (this.playerTwo.results[i].isOutOfBounds) stats.obs++;
        }

        stats.birdies = Math.round(
          (stats.birdies / this.poolData.layoutVersion.holes.length) * 100
        );
        stats.pars = Math.round(
          (stats.pars / this.poolData.layoutVersion.holes.length) * 100
        );
        stats.bogeys = Math.round(
          (stats.bogeys / this.poolData.layoutVersion.holes.length) * 100
        );
        stats.doubleBogeys = Math.round(
          (stats.doubleBogeys / this.poolData.layoutVersion.holes.length) * 100
        );
        stats.trippleBogeysAbove = Math.round(
          (stats.trippleBogeysAbove /
            this.poolData.layoutVersion.holes.length) *
            100
        );
        if (stats.circleHits > 0) {
          stats.conversion = Math.round(
            (stats.conversion / stats.circleHits) * 100
          );
        }
        stats.circleHits = Math.round(
          (stats.circleHits / this.poolData.layoutVersion.holes.length) * 100
        );
        stats.insidePutts = Math.round(
          (stats.insidePutts /
            (this.poolData.layoutVersion.holes.length - stats.outsidePutts)) *
            100
        );
      }
      return stats;
    },
    playerOneStats() {
      let stats = {
        result: 0,
        aces: 0,
        eagles: 0,
        birdies: 0,
        pars: 0,
        bogeys: 0,
        doubleBogeys: 0,
        trippleBogeysAbove: 0,
        circleHits: 0,
        conversion: 0,
        insidePutts: 0,
        outsidePutts: 0,
        obs: 0,
      };
      if (Object.keys(this.playerOne).length > 0) {
        for (var i = 0; i < this.playerOne.results.length; i++) {
          stats.result += this.playerOne.results[i].score;
          if (this.playerOne.results[i].score == 1) stats.aces++;
          else if (
            this.playerOne.results[i].score -
              this.playerOne.results[i].hole.par <=
            -2
          )
            stats.eagles++;
          else if (
            this.playerOne.results[i].score -
              this.playerOne.results[i].hole.par ==
            -1
          )
            stats.birdies++;
          else if (
            this.playerOne.results[i].score -
              this.playerOne.results[i].hole.par ==
            0
          )
            stats.pars++;
          else if (
            this.playerOne.results[i].score -
              this.playerOne.results[i].hole.par ==
            1
          )
            stats.bogeys++;
          else if (
            this.playerOne.results[i].score -
              this.playerOne.results[i].hole.par ==
            2
          )
            stats.doubleBogeys++;
          else if (
            this.playerOne.results[i].score -
              this.playerOne.results[i].hole.par >=
            3
          )
            stats.trippleBogeysAbove++;

          if (this.playerOne.results[i].isCircleHit) stats.circleHits++;
          if (
            this.playerOne.results[i].isCircleHit &&
            this.playerOne.results[i].isInsidePutt
          )
            stats.conversion++;

          if (this.playerOne.results[i].isInsidePutt) stats.insidePutts++;
          if (this.playerOne.results[i].isOutsidePutt) stats.outsidePutts++;
          if (this.playerOne.results[i].isOutOfBounds) stats.obs++;
        }

        stats.birdies = Math.round(
          (stats.birdies / this.poolData.layoutVersion.holes.length) * 100
        );
        stats.pars = Math.round(
          (stats.pars / this.poolData.layoutVersion.holes.length) * 100
        );
        stats.bogeys = Math.round(
          (stats.bogeys / this.poolData.layoutVersion.holes.length) * 100
        );
        stats.doubleBogeys = Math.round(
          (stats.doubleBogeys / this.poolData.layoutVersion.holes.length) * 100
        );
        stats.trippleBogeysAbove = Math.round(
          (stats.trippleBogeysAbove /
            this.poolData.layoutVersion.holes.length) *
            100
        );
        if (stats.circleHits > 0) {
          stats.conversion = Math.round(
            (stats.conversion / stats.circleHits) * 100
          );
        }
        stats.circleHits = Math.round(
          (stats.circleHits / this.poolData.layoutVersion.holes.length) * 100
        );

        stats.insidePutts = Math.round(
          (stats.insidePutts /
            (this.poolData.layoutVersion.holes.length - stats.outsidePutts)) *
            100
        );
      }
      return stats;
    },
    selectablePlayerOnes() {
      return this.players.filter((player) => player != this.playerTwo);
    },
    selectablePlayerTwos() {
      return this.players.filter((player) => player != this.playerOne);
    },
    playerOneProps() {
      let player = {};

      if (Object.keys(this.playerOne).length > 0) {
        player.fullName =
          this.playerOne.firstName + " " + this.playerOne.lastName;
        player.division = this.playerOne.division;

        let playerResults = [];

        this.playerOne.results
          .slice()
          .sort((a, b) => a.hole.number - b.hole.number);

        for (let i = 0; i < this.playerOne.results.length; i++) {
          playerResults.push(this.playerOne.results[i].score);
        }

        player.results = playerResults;
      } else {
        player.fullName = "";
        player.division = "fallback";
        player.results = [];
      }

      return player;
    },
    playerTwoProps() {
      let player = {};

      if (Object.keys(this.playerTwo).length > 0) {
        player.fullName =
          this.playerTwo.firstName + " " + this.playerTwo.lastName;
        player.division = this.playerTwo.division;

        let playerResults = [];

        this.playerTwo.results
          .slice()
          .sort((a, b) => a.hole.number - b.hole.number);

        for (let i = 0; i < this.playerTwo.results.length; i++) {
          playerResults.push(this.playerTwo.results[i].score);
        }

        player.results = playerResults;

        if (player.division == this.playerOneProps.division) {
          player.division = "fallback";
        }
      } else {
        player.fullName = "";
        player.division = "fallback";
        player.results = [];
      }

      if (player.division) return player;
    },
    players() {
      let playerList = [];

      if (!this.loading) {
        for (var i = 0; i < this.poolData.leaderboard.length; i++) {
          for (
            var p = 0;
            p < this.poolData.leaderboard[i].players.length;
            p++
          ) {
            playerList.push(this.poolData.leaderboard[i].players[p]);
          }
        }
      }

      return playerList;
    },
  },
  methods: {
    poolDate(date) {
      return moment(date).format("DD MMMM [at] HH.mm");
    },
    showIfNotZero(value, percentage) {
      if (value != 0) {
        if (percentage) {
          return value + "%";
        } else {
          return value;
        }
      } else return "-";
    },
    winnerCalc(baseValue, compareValue, highWinner) {
      if (highWinner) {
        if (baseValue > compareValue) {
          if (baseValue == 0) {
            return false;
          }

          return true;
        }
      } else {
        if (baseValue < compareValue) {
          if (baseValue == 0) {
            return false;
          }

          return true;
        }
      }
    },
    color(color) {
      return this.convertRGBA(color);
    },
    chartLayout() {
      let poolLayout = [];
      for (var i = 0; i < this.poolData.layoutVersion.holes.length; i++) {
        poolLayout.push(i + 1);
      }
      return poolLayout;
    },
    loadData() {
      this.$axios({
        data: {
          query: `
          {
            pool(poolId:"${this.$router.currentRoute.params.poolId}"){
              areGroupsPublic
              status
              date
              round{
                pools{
                  id
                }
              }
              layoutVersion{
                id
                layout{
                  course{
                    name
                  }
                }
                holes{
                  id
                  number
                  par
                }
              }
              leaderboard{
                name
                type
                id
                players{
                  playerId
                  firstName
                  lastName
                  pdgaNumber
                  pdgaRating
                  results{
                    id
                    score
                    isCircleHit
                    isOutsidePutt
                    isInsidePutt
                    isOutOfBounds
                    hole{
                      id
                      number
                      par
                    }
                  }
                }
              }
            }
          }
          `,
        },
      }).then((result) => {
        this.playerOne = {};
        this.playerTwo = {};

        if (result.data.data.pool.status == "COMPLETED") {
          for (var i = 0; i < result.data.data.pool.leaderboard.length; i++) {
            for (
              var p = 0;
              p < result.data.data.pool.leaderboard[i].players.length;
              p++
            ) {
              result.data.data.pool.leaderboard[i].players[p].division =
                result.data.data.pool.leaderboard[i].type;
            }
          }
        }

        this.poolData = result.data.data.pool;
        this.loading = false;
        this.$store.dispatch("toggleLoading", false);
      });
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    $route: function () {
      this.loadData();
    },
  },
};
</script>

<style scoped lang="scss">
.placeholder-message {
  margin-top: 50px;
  text-align: center;
  h4 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    color: $strom;
  }
}
.rotate-info {
  margin-top: 32px;
}

.message {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 26px;
  font-size: 16px;
  @include Gilroy-Regular;

  button {
    background-color: $peach;
    color: white;
    border: 0px;
    height: 40px;
  }

  b {
    @include Gilroy-Bold;
  }
}
table {
  width: 100%;
  table-layout: fixed;
  margin-top: 20px;
  tr:last-child {
    td {
      border-bottom: 0px solid white;
    }
  }
  td {
    @include Gilroy-Regular;
    background-color: white;
    height: 42px;
    &.player {
      width: 30%;
      text-align: center;

      @include Gilroy-Regular;
    }
    &.first {
      background-color: #e5f8f8;
      &.fat {
        @include Gilroy-Bold;
        color: $ocean;
      }
    }
    &.second {
      background-color: #f7e5ef;
      &.fat {
        @include Gilroy-Bold;
        color: $twilight;
      }
    }
    &.stat-name {
      text-align: center;
      width: 40%;
      @include Gilroy-Regular;
      border-right: 1px solid $halfmouse;
      border-left: 1px solid $halfmouse;
    }
  }
}

.player-select {
  width: 100%;
  display: flex;

  select {
    box-sizing: border-box;
    border-radius: 4px;
    height: 50px;
    width: 100%;
    color: white;
    @include Gilroy-Bold;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: "";
    outline: none;
    border: 0px solid white;

    &.playerone {
      background-color: $ocean;
    }
    &.playertwo {
      background-color: $twilight;
    }
  }
}
.versus {
  font-size: 16px;
  @include Gilroy-Bold;
  margin-bottom: 16px;
  text-align: center;
}
.chart-container {
  display: none;
}
@media (min-width: 480px) and (orientation: landscape) {
  .chart-container {
    display: block;
    margin-top: 44px;
  }
}

@media (min-width: 544px) {
}

@media (min-width: 992px) {
}

@media (min-width: 768px) {
  .chart-container {
    display: block;
    margin-top: 60px;

    padding: 30px;
  }
  .selection-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 0;
    .player-select {
      width: 35%;

      select {
        margin-bottom: 0;
        border-radius: 10px;

        border: 0px solid white;
        height: 48px;
        option {
          background: white;
          color: $midnight;
        }
      }
    }
  }
  table {
    width: 100%;
    table-layout: fixed;
    background-color: white;
    margin-top: 0;

    tbody {
      td {
        font-size: 24px;
        border-bottom: 2px solid white;
        height: 56px;

        &.rounded {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        &.player {
          width: 35%;
          text-align: center;
        }
        &.fat {
          @include Gilroy-Bold;
        }
        &.stat-name {
          text-align: center;
          width: 30%;
          font-size: 18px;
          border-left: 0px;
          border-right: 0px;
        }
      }
    }
  }

  .versus {
    font-size: 15px;
    @include Gilroy-Bold;
    margin: 0;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .placeholder-message {
    text-align: center;
    h4 {
      font-size: 24px;
      margin-bottom: 24px;
    }
    p {
      font-size: 18px;
      color: $strom;
    }
  }
}
</style>
